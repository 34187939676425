import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './tabs.module.css';
import Button from '../../../components/button/button';
import Table from '../../../components/table/table';
import { createDetention, deleteDetention, endDetention, 
    getDetentions, getDetentionSession, 
    updateDetention, updateTeacherDetention } 
    from '../../../actions/detentionActions';
import Input from '../../../components/input/input';
import { convertToCentral } from '../../../util';

const DetentionsTab = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const staff = useSelector(state => state.staff);
    const students = useSelector(state => state.students);
    const detentionsLoaded = useSelector(state => state.detentionsLoaded);
    const detentions = useSelector(state => state.detentions);
    const sessionDetentions = useSelector(state => state.sessionDetentions);

    const [pageState, setPageState] = useState('OPEN');
    const [overrideReload, setOverrideReload] = useState(false);
    const [studentIds, setStudentIds] = useState([]);
    const [assignedId, setAssignedId] = useState(null);
    const [detentionId, setDetentionId] = useState(null);
    const [reason, setReason] = useState('');
    const [showDisclaimer, setShowDisclaimer] = useState(false);

    const handleUpdateTeacher = (teacherId) => {
        if (detentionId && teacherId) {
            dispatch(updateTeacherDetention(detentionId, teacherId)).then(() => {
                dispatch(getDetentions());
                dispatch(getDetentionSession());
                setPageState('OPEN');
            });
        } else {
            alert('Something went wrong. Try again later.');
        }
    };

    const updateStudentIds = (student_id) => {
        if(studentIds.includes(student_id)) {
            setStudentIds(studentIds.filter(student => student !== student_id));
        } else {
            setStudentIds([...studentIds, student_id]);
        }
    }

    useEffect(() => {
        if(!detentionsLoaded) {
            dispatch(getDetentions());
        }
    }, [detentions, detentionsLoaded]);

    useEffect(() => {
        if(!overrideReload) {
            setPageState('OPEN');
            setStudentIds([]);
            setReason('');
            setShowDisclaimer(false);
        } else {
            setOverrideReload(false);
        }
    }, [detentions]);

    const getColumns = () => {
        if(pageState === 'OPEN') {
            return [
                {
                    title: 'First Name',
                    accessor: 'first_name',
                    filter: true
                },
                {
                    title: 'Last Name',
                    accessor: 'last_name',
                    filter: true
                },
                {
                    title: 'Assigned By',
                    accessor: 'assignedBy',
                    filter: true
                },
                {
                    title: 'Reason',
                    accessor: 'reason',
                    filter: true
                },
                {
                    title: 'Date',
                    accessor: 'date',
                    filter: true
                },
                {
                    title: '',
                    accessor: 'id',
                    ignoreSort: true,
                    element: (e) => 
                    <div>
                        {(user.roles.includes('ADMINISTRATOR')) && 
                        <Button
                            value='Edit'
                            onClick={() => {
                                setPageState('EDIT');
                                setDetentionId(e);
                            }}
                            style={{ marginRight: '10px'}}
                        />}
                        <Button value='Remove' onClick={() => dispatch(deleteDetention({ id: e }))}/>
                    </div>
                },
            ];
        }

        if(pageState === 'CREATE') {
            return [
                {
                    title: '',
                    accessor: 'student_id',
                    style: { justifyContent: 'flex-end' },
                    element: (e) => (
                        <input
                            type='checkbox'
                            checked={studentIds.includes(e)}
                            onChange={() => updateStudentIds(e)}
                            style={{ height: '15px', width: '15px'}}
                        />
                    )
                },
                {
                    title: 'Student',
                    accessor: 'studentName',
                    style: { justifyContent: 'flex-start' },
                    filter: true
                },
            ];
        }

        if (pageState === 'EDIT') {
            return [
                {
                    title: 'Name',
                    accessor: 'staffName',
                    filter: true
                },
                {
                    title: '',
                    accessor: 'staffId',
                    filter: true,
                    element: (e) => (
                        <Button
                            value='Select'
                            onClick={() => {handleUpdateTeacher(e)}}
                        />
                    )
                }
            ];
        }       
        
        if (pageState === 'CREATE2') {
            return [
                {
                    title: 'Name',
                    accessor: 'staffName',
                    filter: true
                },
                {
                    title: '',
                    accessor: 'staffId',
                    filter: true,
                    element: (e) => (
                        <Button
                            value='Select'
                            onClick={() => {setAssignedId(e); setPageState('CREATE')}}
                        />
                    )
                }
            ];
        }   

        if(pageState === 'SESSION') {
            return [
                {
                    title: 'First Name',
                    accessor: 'first_name',
                    filter: true
                },
                {
                    title: 'Last Name',
                    accessor: 'last_name',
                    filter: true
                },
                {
                    title: 'Assigned By',
                    accessor: 'assignedBy',
                    filter: true
                },
                {
                    title: 'Reason',
                    accessor: 'reason',
                    filter: true
                },
                {
                    title: 'Date',
                    accessor: 'date',
                    filter: true
                },
                {
                    title: 'Attending',
                    accessor: 'attending',
                    filter: true
                },
                {
                    title: '',
                    accessor: 'id',
                    ignoreSort: true,
                    element: (e) => (
                        <Button
                            value='Attendance'
                            onClick={() => {
                                setOverrideReload(true);
                                dispatch(updateDetention({ id: e }));
                            }}
                        />
                    )
                },
            ];
        }
        
        return [];
    }

    const getData = () => {
        if(pageState === 'OPEN') {
            return detentions.map(detention => {
                let assignedBy = "Admin"
                if(detention.teacher_id) {
                    const teacher = staff.find(teacher => teacher.teacher_id === detention.teacher_id);
                    if(teacher) {
                        assignedBy = `${teacher.last_name}, ${teacher.first_name}`
                    } else {
                        assignedBy = '';
                    }
                }
                return { 
                    ...students.find(student => student.student_id === detention.student_id),
                    ...detention,
                    assignedBy,
                    date: convertToCentral(detention.assignedOn)
                };
            });
        }
        if(pageState === 'CREATE') {
            return students.map(student => ({ ...student, studentName: `${student.last_name}, ${student.first_name}`}));
        }
        if(pageState === 'EDIT' || pageState === 'CREATE2') {
            return staff.map(staff => ({ ...staff, staffId: `${staff.teacher_id}`, staffName: `${staff.last_name}, ${staff.first_name}`}));
        }
        if(pageState === 'SESSION') {
            return sessionDetentions?.map(detention => {
                let assignedBy = "Admin"
                if(detention.teacher_id) {
                    const teacher = staff.find(teacher => teacher.teacher_id === detention.teacher_id);
                    if(teacher) {
                        assignedBy = `${teacher.last_name}, ${teacher.first_name}`
                    } else {
                        assignedBy = '';
                    }
                }
                return { 
                    ...students.find(student => student.student_id === detention.student_id),
                    ...detention,
                    assignedBy,
                    attending: detention.detention_id ? 'Present' : 'Absent',
                    date: convertToCentral(detention.assignedOn)
                };
            });
        }
        return [];
    }

    const getHeaderConfig = () => {
        let header;
        let rightContent;
        let leftContent;

        if(pageState === 'OPEN') {
            header = 'Current Detentions';
            rightContent = (
                <>
                    {(user.roles.includes('ADMINISTRATOR') || user.roles.includes('DETENTION') )&& (
                        <Button
                            onClick={() => {
                                dispatch(getDetentionSession());
                                setPageState('SESSION');
                            }}
                            value='Session'
                            style={{ marginRight: '10px'}}
                        />
                    )}
                    <Button onClick={() => setPageState('CREATE')} value='Create Detention' style={{ marginRight: '10px'}} />
                </>
            )
        }

        if(pageState === 'CREATE') {
            header = 'Create New Detention';
            leftContent = (
                <Button
                    className={style.backButton}
                    onClick={() => {
                        setPageState('OPEN');
                        setStudentIds([]);
                        setReason('');
                        setAssignedId(null);
                    }}
                    value={'< Back'}
                />
            );
            if(studentIds.length > 0) {
                rightContent = (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Input placeholder="Reason" value={reason} onChange={(e) => setReason(e.target.value)} style={{ borderRadius: '0', marginRight: '10px'}} />
                        <Button value="Select Teacher" onClick={() => {setAssignedId(null); setPageState('CREATE2')}} style={{ marginRight: '10px'}} />
                        <Button value="Submit" disabled={reason === ''} onClick={() => {dispatch(createDetention({ student_ids: studentIds, reason, assignedId })); setAssignedId(null)}} style={{ marginRight: '10px'}} />
                    </div>
                );
            }
        }

        if(pageState === 'CREATE2') {
            header = 'Choose Assigned Teacher';
            leftContent = (
                <Button
                    className={style.backButton}
                    onClick={() => {
                        setAssignedId(null);
                        setPageState('CREATE');
                    }}
                    value={'< Back'}
                />
            );
        }

        if(pageState === 'EDIT') {
            header = 'Update Assigned Teacher';
            leftContent = (
                <Button
                    className={style.backButton}
                    onClick={() => {
                        setDetentionId(null);
                        setPageState('OPEN');
                    }}
                    value={'< Back'}
                />
            );
        }

        if(pageState === 'SESSION') {
            header = 'Detention Session';
            leftContent = (
                <Button
                    className={style.backButton}
                    onClick={() => setPageState('OPEN')}
                    value={'< Back'}
                />
            );
            rightContent = (
                <Button
                    onClick={() => setShowDisclaimer(true)}
                    value={'End Detention'}
                    style={{ marginRight: '10px'}}
                />
            );
        }

        return {
            leftContent,
            rightContent,
            middleContent: <h2 className={style.header}>{header}</h2>
        };
    }

    const showDisclaimerPage = (
        <div className={style.centerContain}>
            <h3 className={style.header} style={{ margin: '10px 0'}}>Are you sure you want to Submit Detention for Review?</h3>
            <h4 className={style.header} style={{ margin: '10px 0'}}>You will not be able to make any additional edits.</h4>
            <Button value="No" onClick={() => setShowDisclaimer(false)} style={{ marginRight: '10px'}}/>
            <Button value="Yes" onClick={() => dispatch(endDetention())} />
        </div>
    );

    const getContent = () => {
        if(showDisclaimer) {
            return showDisclaimerPage;
        }
        return <Table columns={getColumns()} data={getData()} headerConfig={getHeaderConfig()} sortOverride={'first_name'} />
    }

    return (
        <div className={style.blockContainer}>
            {getContent()}
        </div>
    )
};

export default DetentionsTab;