import React, { useState, useEffect } from 'react';
import Table from '../../../components/table/table';
import { convertToCentral, convertToDateCentral, convertVisitData } from '../../../util';
import Button from '../../../components/button/button';
import style from './tabs.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { getVisits } from '../../../actions/visitActions';
import Calendar from 'react-calendar';
import Modal from '../../../components/modal/modal';
import 'react-calendar/dist/Calendar.css';

const oneMinute = 60 * 1000;
const oneHour = 60 * oneMinute;

const startOfDay = (dayObject) => {
    dayObject.setHours(0);
    dayObject.setMinutes(0);
    dayObject.setSeconds(0);

    return dayObject;
}

const endOfDay = (dayObject) => {
    dayObject.setHours(23);
    dayObject.setMinutes(59);
    dayObject.setSeconds(59);

    return dayObject;
};

const StudentVisitsTab = () => {
    const dispatch = useDispatch();
    const visits = useSelector(state => state.visits);
    const students = useSelector(state => state.students);
    const locations = useSelector(state => state.locations);
    const staff = useSelector(state => state.staff);
    const courses = useSelector(state => state.courses);


    const initialDateRange = [startOfDay(new Date(new Date().getTime() - (7 * 24 * oneHour))), endOfDay(new Date())];

    const [dateSelection, changeDateSelection] = useState(initialDateRange);
    const [dateSelecting, setDateSelecting] = useState(false);

    useEffect(() => {
        if(!dateSelecting) {
            dispatch(getVisits(dateSelection));
        }
    }, [dateSelecting])



    const handleChangeDateSelection = (newDateSelection) => {
        if(dateSelection.length === 1) {
            // add a second date
            const unsortedDateSelection = [dateSelection[0], endOfDay(newDateSelection)];

            changeDateSelection(unsortedDateSelection.sort((a, b) => (a - b)));
            setDateSelecting(false);
        } else {
            changeDateSelection([newDateSelection]); 
        }
    }

    const handleDateDismiss = () => {
        if(dateSelection.length === 1) {
            const unsortedDateSelection = [dateSelection[0], endOfDay(dateSelection[0])];
            changeDateSelection(unsortedDateSelection.sort((a, b) => (a - b)));
        }
        setDateSelecting(false);
    }
    

    const convertedVisits = convertVisitData(visits, students, locations, staff, courses);
    const data = convertedVisits.map(visit => ({
        ...visit,
        timeInString: convertToCentral(visit.timeIn),
        timeOutString: convertToCentral(visit.timeOut),
    }));
    const columns = [
        {
            title: 'Student',
            accessor: 'student',
            filter: true
        },
        {
            title: 'Location',
            accessor: 'location',
            filter: true
        },
        {
            title: 'Teacher',
            accessor: 'teacher',
            filter: true
        },
        {
            title: 'Class',
            accessor: 'course',
            filter: true
        },
        {
            title: 'Time In',
            accessor: 'timeInString',
            value: 'timeIn'
        },
        {
            title: 'Time Out',
            accessor: 'timeOutString',
            value: 'timeOut'
        }
    ];
    const headerConfig =  {
        middleContent: <h2 className={style.header}>Student Visits</h2>,
        rightContent: (
            <Button
                onClick={() => setDateSelecting(true)}
                value={`${convertToDateCentral(dateSelection[0])}${(dateSelection.length > 1) ? ` - ${convertToDateCentral(dateSelection[1])}` : ''}`}
                style={{ marginRight: '10px'}}
            />
        )
    };
    return (
        <>
            <Modal display={dateSelecting} dismiss={handleDateDismiss}>
                <Calendar onChange={handleChangeDateSelection} value={dateSelection}/>
            </Modal>
            <Table columns={columns} data={data} headerConfig={headerConfig} sortOverride={'timeIn'} />
        </>
        
    )
};


export default StudentVisitsTab;